/* Universal Laws */
body {
  background-color: #efefee;
  font-family: 'Open Sans', sans-serif;
  margin: 0px;
}

p {
  margin: 0;
  font-family: typold, verdana, sans-serif;
}




/* INTRO */
#intro {
  padding-top: 160px;
}

@media screen and (min-width: 480px) {
  #intro {
      padding-top: 180px;
  }
}

@media screen and (min-width: 768px) {
  #intro {
      padding-top: 210px;
  }
}

@media screen and (min-width: 990px) {
  #intro {
      padding-top: 220px;
  }
}


/* CONTAINER */
.container {
  height: auto;
  width: 90%;
  margin: auto;
  padding-bottom: 75px;
  /* was formally min-height 440px, before it was changed to padding-bottom 75px */
}

@media screen and (min-width: 480px) {
  .container {
  height: auto;
  min-height: 350px;
  width: 86%;
  }
}

@media screen and (min-width: 768px) {
  .container {
  height: auto;
  width: 82%;
  margin-bottom: 125px;
  }
}

@media screen and (min-width: 990px) {
  .container {
  height: auto;
  width: 82%;
  margin-bottom: 140px;
  }
}






/* Work needed here! */
.projects-container {
  height: auto;
  /* min-height: 240px; */
  width: 90%;
  margin: auto;
  padding-bottom: 100px;
}

@media screen and (min-width: 480px) {
  .projects-container {
  height: auto;
  min-height: 350px;
  width: 86%;
  }
}

@media screen and (min-width: 768px) {
  .projects-container {
  height: auto;
  width: 82%;
  margin-bottom: 125px;
  }
}

@media screen and (min-width: 990px) {
  .projects-container {
  height: auto;
  width: 82%;
  margin-bottom: 140px;
  }
}






/* CONNECT / SOCIAL MEDIA */
.connect-container {
  position: absolute;
  display: flex;
  flex-direction: row;
  top: 50px;
}

.linked-in-icon {
  margin-right: 16px;
  border-radius: 50px;
  width: 36px;
  opacity: 95%;
}

@media screen and (min-width: 480px) {
  .linked-in-icon {
    margin-right: 18px;
    width: 40px;
  }
}

@media screen and (min-width: 768px) {
  .linked-in-icon {
    margin-right: 20px;
    width: 40px;
  }
}

@media screen and (min-width: 990px) {
  .linked-in-icon {
    margin-right: 16px;
    width: 36px;
  }
}





.github-icon {
  width: 36px;
  margin-right: 16px;
  border-radius: 50px;
  opacity: 100%;
}

@media screen and (min-width: 480px) {
  .github-icon {
    margin-right: 18px;
    width: 40px;
  }
}

@media screen and (min-width: 768px) {
  .github-icon {
    margin-right: 20px;
    width: 40px;
  }
}

@media screen and (min-width: 990px) {
  .github-icon {
    margin-right: 16px;
    width: 36px;
  }
}


.mail-icon {
  margin-right: 16px;
  width: 36px;
  border-radius: 50px;
  opacity: 100%;
}

@media screen and (min-width: 480px) {
  .mail-icon {
    margin-right: 18px;
    width: 40px;
  }
}

@media screen and (min-width: 768px) {
  .mail-icon {
    margin-right: 20px;
    width: 40px;
  }
}

@media screen and (min-width: 990px) {
  .mail-icon {
    margin-right: 16px;
    width: 36px;
  }
}


.download-icon {
  width: 36px;
  border-radius: 50px;
  opacity: 100%;
}

@media screen and (min-width: 480px) {
  .download-icon {
    margin-right: 18px;
    width: 40px;
  }
}

@media screen and (min-width: 768px) {
  .download-icon {
    margin-right: 20px;
    width: 40px;
  }
}

@media screen and (min-width: 990px) {
  .download-icon {
    margin-right: 16px;
    width: 36px;
  }
}


/* CONTAINER IMAGE */
.container img  {
  object-fit: cover;
  width: 100%;
  border-radius: 3px;
  margin-bottom: 6px;
}

@media screen and (min-width: 480px) {
  .container img {
    border-radius: 4px;
    margin-bottom: 10px;
  }
}

@media screen and (min-width: 768px) {
  .container img {
    border-radius: 4px;
    margin-bottom: 14px;
  }
}

@media screen and (min-width: 990px) {
  .container img {
    border-radius: 0px;
    margin-bottom: 16px;
    object-fit: cover;
  }
}





/* CONTAINER IMAGE */
.projects-container img  {
  object-fit: cover;
  width: 100%;
  border-radius: 3px;
  margin-bottom: 6px;
}

@media screen and (min-width: 480px) {
  .projects-container img {
    border-radius: 4px;
    margin-bottom: 10px;
  }
}

@media screen and (min-width: 768px) {
  .projects-container img {
    border-radius: 4px;
    margin-bottom: 14px;
  }
}

@media screen and (min-width: 990px) {
  .container img {
    border-radius: 0px;
    margin-bottom: 16px;
    object-fit: cover;
  }
}




.projects-container h1 {
  font-size: 5rem;
  line-height: 4.6rem;
  letter-spacing: 0rem;
  color: #474747;
  margin: 0;
}

@media screen and (min-width: 480px) {
  .projects-container h1 {
      font-size: 6rem;
      line-height: 5.3rem;
  }
}

@media screen and (min-width: 768px) {
  .projects-container h1 {
      font-size: 7rem;
      line-height: 6.5rem;
  }
}

@media screen and (min-width: 990px) {
  .projects-container h1 {
      font-size: 7rem;
      line-height: 6.2rem;
  }
}

.hidden-on-mobile {
  display: none;
}

@media screen and (min-width: 990px) {
  .hidden-on-mobile {
display: block;
padding-top: 80px;
  }
}




/* PROJECT IMAGE */
    #project-homepage {
    height: auto;
}

@media screen and (min-width: 480px) {
    #project-homepage {
    height: auto;
    margin-top: 80px;
}
}

@media screen and (min-width: 768px) {
  #project-homepage {
  height: auto;
  margin-top: 0px;
}
}

@media screen and (min-width: 990px) {
  #project-homepage {
    height: 650px;
    max-width: 1200px;
}
}

@media screen and (min-width: 990px) {
  .project-image-responsive {
    display: none;
  }
}

@media screen and (max-width: 990px) {
  .project-image-desktop {
    display: none;
  }
}



/* H1  /  HEADER */
.container h1 {
  font-size: 5rem;
  line-height: 4.6rem;
  letter-spacing: 0rem;
  color: #474747;
  margin: 0;
}

@media screen and (min-width: 480px) {
  .container h1 {
      font-size: 6rem;
      line-height: 5.3rem;
  }
}

@media screen and (min-width: 768px) {
  .container h1 {
      font-size: 7rem;
      line-height: 6.5rem;
  }
}

@media screen and (min-width: 990px) {
  .container h1 {
      font-size: 7rem;
      line-height: 6.2rem;
  }
}


#intro-job-title {
  margin-top: 0px;
  font-family: Helvetica, sans-serif;
}

@media screen and (min-width: 480px) {
  #intro-job-title {
      margin-top: 3px;
  }
}

@media screen and (min-width: 768px) {
  #intro-job-title {
      margin-top: 6px;
  }
}

@media screen and (min-width: 990px) {
  #intro-job-title {
      margin-top: 4px;
  }
}


.contact-second-title {
  font-weight: 600;
  color: #b3b3b3;
  font-size: 1.15rem;
  line-height: 1.6rem;
  letter-spacing: -0.025;
  margin-top: 0px;
}


/* H2 */
#intro-job-title h2 {
  font-weight: 600;
  font-size: 1.2rem;
  line-height: 1.9rem;
  color: #b3b3b3;
  letter-spacing: -0.025;
  margin-top: 0px;
  margin-bottom: 0;
}

@media screen and (min-width: 480px) {
  #intro-job-title h2 {
      font-size: 1.35rem;
  }
}

@media screen and (min-width: 768px) {
  #intro-job-title h2 {
      font-size: 1.48rem;
  }
}

@media screen and (min-width: 990px) {
  #intro-job-title h2 {
      font-size: 1.4rem;
  }
}


#intro-job-title h3 {
  font-weight: 600;
  font-size: 0.8rem;
  font-weight: 900;
  line-height: 1.4rem;
  color: #b3b3b3;
  letter-spacing: -0.025;
  margin-top: 0px;
}


#intro-job-title p {
  font-size: 0.85rem;
  line-height: 1.4rem;
  color: #4e4e4e;
  margin-top: 40px;
}

@media screen and (min-width: 480px) {
  #intro-job-title p {
      margin-top: 52px;
      font-size: 1rem;
      line-height: 1.65rem;
  }
}

@media screen and (min-width: 768px) {
  #intro-job-title p {
      margin-top: 70px;
      font-size: 1rem;
      line-height: 1.9rem;
  }
}

@media screen and (min-width: 990px) {
  #intro-job-title p {
      width: 65%;
      max-width: 1200px;
      margin-top: 40px;
      font-size: 1rem;
      line-height: 1.75rem;
  }
}


.contact-container {
  margin-top: 50px;
}

.contact-container h3 {
  font-family: typold, verdana, sans-serif;
  font-size: 1rem;
  line-height: 1.4rem;
  color: #4e4e4e;
  margin-top: 30px;
  margin-bottom: 0px;
}

.contact-container a {
  font-size: 0.85rem;
  line-height: 1.4rem;
  color: #4e4e4e;
  margin: 0px;
}


.project-description-container h2 {
  font-family: Helvetica, sans-serif;
  margin-top: 0;
  color: #b3b3b3;
  font-size: 1.2rem;
}

@media screen and (min-width: 480px) {
  .project-description-container h2 {
    font-size: 1.35rem;
  }
}

@media screen and (min-width: 768px) {
  .project-description-container h2 {
    font-size: 1.65rem;
  }
}

@media screen and (min-width: 990px) {
  .project-description-container h2 {
    font-size: 1.4rem;
  }
}


.standard-project-description {
  margin-top: 60px;
}
@media screen and (min-width: 480px) {
  .standard-project-description {
    margin-top: 75px;
  }
}
@media screen and (min-width: 768px) {
  .standard-project-description {
    margin-top: 50px;
  }
}
@media screen and (min-width: 990px) {
  .standard-project-description {
    margin-top: 50px;
  }
}


.visit-project-link {
  margin-top: 80px;
  margin-bottom: 40px;
  color: #3c3c3c;
}

.visit-project-link{
  color: #b3b3b3;
}


.project-description-container p {
  font-size: 0.85rem;
  line-height: 1.4rem;
  color: #4e4e4e;
  margin-top: 10px;
}

@media screen and (min-width: 480px) {
  .project-description-container p {
    font-size: 1rem;
    line-height: 1.65rem;
  }
}

@media screen and (min-width: 768px) {
  .project-description-container p {
    font-size: 1rem;
    line-height: 1.9rem;
  }
}

@media screen and (min-width: 990px) {
  .project-description-container p {
    width: 65%;
    font-size: 1rem;
    line-height: 1.75rem;
  }
}


ul {
  padding: 0;
  width: 86%;
  margin: auto;
}

@media screen and (min-width: 480px) {
  ul {
    width: 86%;
  }
}

@media screen and (min-width: 768px) {
  ul {
    width: 82%;
  }
}

@media screen and (min-width: 990px) {
  ul {
    width: 82%;
  }
}


/* MENU */
.menu {
  height: 100%;
  width: 100%;
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  background-color: #d3d3d2;
  z-index: 1;
  position: fixed;
  top: 0%;
  right: 0%;
  bottom: 0%;
  left: 0%;
  opacity: 0;
  transition: opacity 0.5s;
  animation: menu-animation 0.75s linear;
}


.menu .other-pages ul li {
  list-style-type: none;
  font-size: 5rem;
  line-height: 4.4rem;
  text-decoration: none;
}

@media screen and (min-width: 480px) {
  .menu .other-pages ul li {
      font-size: 6rem;
      line-height: 5.3rem;
  }
}

@media screen and (min-width: 768px) {
  .menu .other-pages ul li {
      font-size: 7rem;
      line-height: 6.5rem;
  }
}

@media screen and (min-width: 990px) {
  .menu .other-pages ul li {
      font-size: 7rem;
      line-height: 6.2rem;
  }
}


/* Projects Menu */
.projects-menu {
  height: 100%;
  width: 100%;
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  background-color:  #efefee;
  z-index: 3;
  position: fixed;
  top: 0%;
  right: 0%;
  bottom: 0%;
  left: 0%;
  animation: menu-animation 0.75s linear;
}

.projects-menu ul li {
  list-style-type: none;
  text-transform: uppercase;
  font-size: 5rem;
  line-height: 4.5rem;
  font-weight: bold;
  letter-spacing: -0.1rem;
  color: #474747;
}


a {
  text-decoration: none;
  color: #474747;
}


.burger-line {
  width: 28px;
  height: 3px;
  margin-top: 2px;
  margin-bottom: 3px;
  background-color: #818181;
}


/* burger menu */
.burger-menu {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 50px;
  z-index: 2;
  right: 0;
  top: 0;
  margin: 40px 10px 0 0;
  cursor: pointer;
}

@media screen and (min-width: 990px) {
  .burger-menu {
    margin: 40px 40px 0 0;
  }
}


.arrow-menu {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 50px;
  z-index: 4;
  right: 0;
  top: 0;
  margin: 40px 10px 0 0;
  cursor: pointer;
}

.arrow-line {
  width: 25px;
  height: 3px;
  transform: rotate(160deg);
  background-color: black;
  border-radius: 5px;
  background-color: #474747;
}

.arrow-line-2 {
  width: 25px;
  height: 3px;
  transform: rotate(200deg);
  background-color: black;
  margin-top: 5px;
  border-radius: 4px;
  background-color: #474747;
}


footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 35px 0 35px 0;
  color: #b6b6b6;
  line-height: 1.25rem;
}


/* Menu Animations */
.flex {
  display: flex;
}

.appear {
  opacity: 1;
}


.other-pages {
  opacity: 0%;
  animation: menu-animation-2 1.5s linear 1s;
  animation-fill-mode: forwards;
}


@keyframes menu-animation-2 {
  0% {
      opacity: 0%;
  }

  100% {
      opacity: 100%;
  }
}

@keyframes menu-animation {
  0% {
      opacity: 0%;
  }

  100% {
      opacity: 100%;
  }
}

@keyframes move-links {
  0% {
      transform: translate();
  }
}


.image-filler {
  height: 80px;
  margin-bottom: 0;
}
@media screen and (min-width: 480px) {
  .image-filler {
    height: 100px;
  }
}
@media screen and (min-width: 768px) {
  .image-filler {
    height: 100px;
  }
}


.describing-picture-above {
  margin-top: -40px;
}
@media screen and (min-width: 480px) {
  .describing-picture-above {
    margin-top: -60px;
  }
}
@media screen and (min-width: 768px) {
  .describing-picture-above {
    margin-top: -80px;
  }
}
@media screen and (min-width: 990px) {
  .describing-picture-above {
    margin-top: -50px;
  }
}

.buyan small {
  font-size: 2.3rem;
}


.portfolio-vid-container {
  background-color: #efefee;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

video {
  width: 100%;
}

.pre {
  font-size: 0.5rem;
  line-height: 0.9rem;
  font-family: monospace, sans-serif;
  font-weight: 500;
  color: gray;
  background-color: #222;
  padding: 20px;
  border-radius: 5px;
  margin-bottom: 90px;
}

@media screen and (min-width: 480px) {
  .pre {
    font-size: 0.6rem;
    line-height: 1.5rem;
    margin-bottom: 125px;
  }
}

@media screen and (min-width: 768px) {
  .pre {
    font-size: 0.85rem;
    line-height: 1.65rem;
    margin-bottom: 150px;
  }
}

@media screen and (min-width: 990px) {
  .pre {
    font-size: 1rem;
    line-height: 1.65rem;
    margin-bottom: 150px;
  }
}

.pre-blue {
  color: dodgerblue;
}

.pre-light-blue {
  color: #9cdcfe;
}

.pre-coral {
  color: #ce9178;
}

.pre-white {
  color: white;
}

.pre-yellow {
  color: #dcdcaa;
}

.pre-purple {
  color: #c586c0;
}

.pre-amber {
  color: orange;
}

.pre-cyan {
  color: #4ec8af;
}


.filter-p {
  margin-bottom: 75px;
}

@media screen and (min-width: 480px) {
  .portfolio-vid-container {
    width: 90%;
    margin: 0 auto;

  }
}

@media screen and (min-width: 768px) {
  .portfolio-vid-container {
    width: 60%;
  }
}


@media screen and (min-width: 990px) {
  .portfolio-vid-container {
    width: 55%;
  }
}


.filter-p {
  margin-top: -100px;
  margin-bottom: 100px;
}

@media screen and (min-width: 480px) {
  .filter-p {
    margin-top: -125px;
    margin-bottom: 125px;
  }
}

@media screen and (min-width: 768px) {
  .filter-p {
    margin-top: -150px;
    margin-bottom: 175px;
  }
}